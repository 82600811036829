<template>
  <div>
    <DataTable
      v-bind="{
        list: orgsPage,
        pending,
        error,
        totalItems,
        pageSize,
        filters,
        header,
      }"
      @selected:filter="onFilterSelect"
    >
      <template #header:status="{ item: label }">
        <th class="text-center">
          {{ label }}
        </th>
      </template>

      <template #header:actions="{ item: label }">
        <th class="text-center pr-11">
          {{ label }}
        </th>
      </template>

      <template #col:name="{ item: org }">
        <td class="font-bold pl-11 truncate">
          {{ org.name }}
          <sup
            v-if="
              org.isSolutionPartner ||
              org.isFinancePartner ||
              org.isBuyer ||
              org.isTestAccount
            "
            >{{ flagOrg(org) }}</sup
          >
        </td>
      </template>

      <template #col:id="{ item: org }">
        <td>{{ org.id }}</td>
      </template>

      <template #col:address="{ item: org }">
        <td>{{ org.street }}, {{ org.zip }} {{ org.city }}</td>
      </template>

      <template #col:status="{ item: org }">
        <td :class="org.active ? 'text-primary' : 'text-darkGray'">
          <div
            class="mx-auto pl-3 w-[116px] cursor-pointer"
            @click="openStatusDialog(org)"
          >
            <Icon
              class="inline-block w-5 xl:w6"
              :name="org.active ? 'check' : 'cancel'"
            />
            <span class="font-semibold align-middle ml-2">
              {{ $t(`accountManagement.${org.active ? 'active' : 'locked'}`) }}
            </span>
          </div>
        </td>
      </template>

      <template #col:actions="{ item: org }">
        <td class="text-center pr-2 xl:pr-11">
          <Icon
            class="inline-block cursor-pointer w-5 xl:w-6"
            name="user-settings"
            @click="openContactsDialog(org)"
          />
          <Icon
            class="
              inline-block
              stroke-current
              cursor-pointer
              w-5
              ml-5
              xl:w-6 xl:ml-7
            "
            name="edit"
            @click="openEditDialog(org)"
          />
          <Icon
            class="
              inline-block
              stroke-current
              cursor-pointer
              w-5
              ml-5
              xl:w-6 xl:ml-7
            "
            :class="{
              'opacity-50 pointer-events-none':
                org.users?.length > 0 || org.assetGroups?.length > 0,
            }"
            name="trash"
            @click="openDeleteDialog(org)"
          />
        </td>
      </template>
    </DataTable>

    <EditContactsDialog
      v-model="contactsDialog"
      :organisation="selectedOrg"
      @edit="editOrg"
    />
    <EditOrganisationDialog
      v-model="editDialog"
      :organisation="selectedOrg"
      @finish="editOrg"
    />
    <ActivateOrganisationDialog
      v-model="activateDialog"
      :organisation="selectedOrg"
      @activate="activateOrg"
    />
    <DeactivateOrganisationDialog
      v-model="deactivateDialog"
      :organisation="selectedOrg"
      @deactivate="deactivateOrg"
    />
    <DeleteOrganisationDialog
      v-model="deleteDialog"
      :organisation="selectedOrg"
      @delete="deleteOrg"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { DataTable } from '@/components/dataTable'
import Icon from '@/components/Icon'
import {
  EditContactsDialog,
  EditOrganisationDialog,
  DeactivateOrganisationDialog,
  ActivateOrganisationDialog,
  DeleteOrganisationDialog,
} from '@/components/dialog'
import { useListFetch } from '@/composition'
import { general } from '@/helpers'

export default {
  components: {
    DataTable,
    Icon,
    EditContactsDialog,
    EditOrganisationDialog,
    ActivateOrganisationDialog,
    DeactivateOrganisationDialog,
    DeleteOrganisationDialog,
  },
  setup() {
    const store = useStore()
    const errorMessage = () =>
      store.dispatch('toastMessage/showMessage', {
        type: 'error',
        translationKey: 'organisations-list-fetch-error',
      })
    return useListFetch('organisations', errorMessage)
  },
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    pageSlice: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  emits: ['update:table'],
  data() {
    return {
      activeFilter: 'all',
      activateDialog: false,
      deactivateDialog: false,
      contactsDialog: false,
      editDialog: false,
      deleteDialog: false,
      selectedOrg: null,
    }
  },
  inject: ['newOrg'],
  computed: {
    activeOrgs() {
      return this.list.filter(u => u.active)
    },
    inactiveOrgs() {
      return this.list.filter(u => !u.active)
    },
    filteredOrgs() {
      switch (this.activeFilter) {
        case 'active':
          return this.activeOrgs
        case 'locked':
          return this.inactiveOrgs
        case 'pending':
          return []
        default:
          return this.list
      }
    },
    searchTerm() {
      return this.search.trim().toLowerCase()
    },
    searchedFilteredOrgs() {
      const searchKeys = ['name', 'id', 'city', 'street'] // TODO: is this correct?
      return general.searchOverKeys(
        this.filteredOrgs,
        searchKeys,
        this.searchTerm
      )
    },
    orgsPage() {
      return this.searchedFilteredOrgs.slice(...this.pageSlice)
    },
    filters() {
      return [
        {
          key: 'all',
          item: {
            label: this.$t('accountManagement.all'),
            active: this.activeFilter === 'all',
            number: this.list.length,
          },
        },
        {
          key: 'active',
          item: {
            label: this.$t('accountManagement.active'),
            active: this.activeFilter === 'active',
            number: this.activeOrgs.length,
          },
        },
        {
          key: 'locked',
          item: {
            label: this.$t('accountManagement.locked'),
            active: this.activeFilter === 'locked',
            number: this.inactiveOrgs.length,
          },
        },
        {
          key: 'pending',
          item: {
            label: this.$t('accountManagement.pending'),
            active: this.activeFilter === 'pending',
            number: 0,
          },
        },
      ]
    },
    header() {
      return ['name', 'id', 'address', 'status', 'actions'].map(key => ({
        key,
        item: this.$t(`accountManagement.organisations-${key}`),
      }))
    },
  },
  methods: {
    onFilterSelect(key) {
      this.activeFilter = key
    },
    openStatusDialog(org) {
      console.log('open status dialog', org)
      // TODO: uncomment once the feature is supported by the api
      // this.selectedOrg = org
      // if (org.active) {
      //   this.deactivateDialog = true
      // } else {
      //   this.activateDialog = true
      // }
    },
    openContactsDialog(org) {
      this.selectedOrg = org
      this.contactsDialog = true
    },
    openEditDialog(org) {
      this.selectedOrg = org
      this.editDialog = true
    },
    openDeleteDialog(org) {
      this.selectedOrg = org
      this.deleteDialog = true
    },
    activateOrg(org) {
      this.list.find(o => o.id === org.id).active = true
    },
    deactivateOrg(org) {
      this.list.find(o => o.id === org.id).active = false
    },
    editOrg(org) {
      const idx = this.list.findIndex(o => o.id === org.id)
      this.list[idx] = org
    },
    deleteOrg(org) {
      this.list = this.list.filter(o => o.id !== org.id)
    },
    flagOrg(org) {
      let flag = ''
      if (org.isSolutionPartner)
        flag =
          flag + ' ' + this.$t('accountManagement.solutionPartner-abbreviation')
      if (org.isFinancePartner)
        flag =
          flag + ' ' + this.$t('accountManagement.financePartner-abbreviation')
      if (org.isBuyer)
        flag = flag + ' ' + this.$t('accountManagement.isBuyer-abbreviation')
      if (org.isTestAccount)
        flag =
          flag + ' ' + this.$t('accountManagement.isTestAccount-abbreviation')
      return flag
    },
  },
  watch: {
    'searchedFilteredOrgs'(newList) {
      this.$emit('update:table', newList.length)
    },
    'newOrg.value'(org) {
      this.list.push(org)
    },
  },
}
</script>
